module services {
    export module applicationcore {
        export class containerISOService implements interfaces.applicationcore.IContainerISOService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getContainerISODropdown(searchText: string): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "ContainerISO/GetContainerISODropdown", {
                    searchText: searchText
                });
            }
        }
    }
    angular.module("app").service("containerISOService", services.applicationcore.containerISOService);
}